.authorization-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    padding: 30px;
    width: 450px;
    border-radius: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  ::placeholder {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .authorization-form button {
    align-self: flex-end;
  }
  
  .authorization-flex-column > label {
    color: #151717;
    font-weight: 600;
  }
  
  .authorization-inputForm {
    border: 1.5px solid #ecedec;
    border-radius: 5px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: 0.2s ease-in-out;
  }
  
  .authorization-input {
    margin-left: 10px;
    border-radius: 10px;
    border: none;
    width: 85%;
    height: 100%;
  }
  
  .authorization-input:focus {
    outline: none;
  }
  
  .authorization-inputForm:focus-within {
    border: 1.5px solid #1f2937;
  }
  
  .authorization-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }
  
  .authorization-flex-row > div > label {
    font-size: 14px;
    color: black;
    font-weight: 400;
  }
  
  .authorization-span {
    font-size: 14px;
    text-decoration: underline;
    margin-left: 5px;
    color: #1f2937;
    font-weight: 500;
    cursor: pointer;
  }
  
  .authorization-button-submit {
    margin: 20px 0 10px 0;
    background-color: #1f2937;
    border: none;
    color: white;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
    height: 50px;
    width: 100%;
    cursor: pointer;
  }
  
  .authorization-button-submit:hover {
    background-color: #131922;
  }
  
  .authorization-p {
    text-align: center;
    color: black;
    font-size: 14px;
    margin: 5px 0;
  }
  
  .authorization-btn {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    gap: 10px;
    border: 1px solid #ededef;
    background-color: white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }
  
  .authorization-btn:hover {
    border: 1px solid #1f2937;
    ;
  }
  
      